import React, { useState } from "react"
import { graphql } from "gatsby"
import Axios from "axios"
import useForm from "react-hook-form"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import RuledBreak from "../../components/RuledBreak"
import { ContactForm as Form, Success, Danger } from "../../components/Form"
import { ButtonTrace } from "../../components/Button"
import { BallTriangle as Loading } from "../../components/Common/loaders"

import { URLS } from "../../config"

const Contact = ({ data }) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState("")
  const [err, setError] = useState("")
  const { register, handleSubmit, errors, setValue } = useForm()
  const onSubmit = async data => {
    const { fname, lname, email, message } = data
    try {
      setLoading(true)
      const { data } = await Axios.post(`${URLS.SERVER}/contact`, {
        fname,
        lname,
        email,
        message,
      })
      setSuccess(data.message)
      setLoading(false)
      setValue("fname", "")
      setValue("lname", "")
      setValue("email", "")
      setValue("message", "")
    } catch (error) {
      console.log("Contact Error:", error)
      setError(error.response.data.message)
      setLoading(false)
    }
  }

  const renderButton = () => {
    if (loading) {
      return (
        <div className="row">
          <div className="col-md-12 text-center">
            <Loading className="ball-tri-loader" />
          </div>
        </div>
      )
    } else {
      return (
        <div className="row">
          <div className="col-12 text-center">
            <ButtonTrace onClick={handleSubmit(onSubmit)} btn={3}>
              Submit
            </ButtonTrace>
          </div>
        </div>
      )
    }
  }
  const headerImg = data.header.edges[0].node.childImageSharp.fluid
  return (
    <Layout
      fluid={headerImg}
      alt={"Cali Reeves answering questions on phone"}
      height={400}
      mobile={300}
    >
      <SEO title="Contact" keywords={["Eyelashes", "Cali Reeves"]} />
      <div className="row mt-5">
        <div className="col-md-12">
          <RuledBreak>Contact Me</RuledBreak>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mr-auto ml-auto">
          <div className="container-fluid">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12">
                  <label htmlFor="fname">First name</label>
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    name="fname"
                    ref={register({
                      required: "First Name is required",
                      maxLength: 80,
                    })}
                  />
                </div>
                <div className="col-12">
                  {errors.fname && <p>{errors.fname.message}</p>}
                </div>
              </div>
              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
              <div className="row">
                <div className="col-12">
                  <label htmlFor="lname">Last name</label>
                </div>

                <div className="col-12">
                  <input
                    type="text"
                    name="lname"
                    ref={register({
                      required: "Last Name is required",
                      maxLength: 50,
                    })}
                  />
                </div>

                <div className="col-12">
                  {errors.lname && <p>{errors.lname.message}</p>}
                </div>
              </div>
              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
              <div className="row">
                <div className="col-12">
                  <label htmlFor="email">Email</label>
                </div>

                <div className="col-12">
                  <input
                    type="email"
                    name="email"
                    ref={register({
                      required: "Email is required",
                      pattern: {
                        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Invalid email address",
                      },
                    })}
                  />
                </div>

                <div className="col-12">
                  {errors.email && <p>{errors.email.message}</p>}
                </div>
              </div>
              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
              <div className="row">
                <div className="col-12">
                  <label htmlFor="message">Message</label>
                </div>

                <div className="col-12">
                  <textarea
                    type="text"
                    name="message"
                    ref={register({
                      required: "Message is required",
                    })}
                  />
                </div>

                <div className="col-12">
                  {errors.message && <p>{errors.message.message}</p>}
                </div>
              </div>
              {renderButton()}
              {success && <Success>{success}</Success>}
              {err && <Danger>{err}</Danger>}
            </Form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query contactQuery {
    header: allFile(filter: { name: { eq: "contact_header_1201x834" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default Contact
